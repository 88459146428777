/*
    Project: Martfury - Marketplace html template.
    Version: 2.1.1
    Date Created: 06/12/2018
    Date Updated: 2020-12-14T14:10:47+07:00
    Author: Nouthemes
    Developed by: diaryforlife
    Homepage: https://themeforest.net/user/nouthemes/portfolio
*/

/*
    TABLE OF CONTENT
        1. RESET AND TYPHOGRAPHY
            1.1 reset
            1.2 typhography
        2. ELEMENTS
            2.1 grid
            2.2 list
            2.3 button
            2.4 form
            2.5 post
            2.6 pagination
            2.7 breadcrumb
            2.8 block
            2.9 widget
            2.10 slider
            2.11 masonry
            2.12 product
            2.13 banner
            2.14 filter
            2.15 table
            2.16 loader
            2.17 modal
        3. COMPONENTS
             3.1 Cart
             3.2 forms
             3.3 search
             3.4 section
             3.5 pages
        4. MODULES
            4.1 header
            4.2 footer
        5. HELPERS
        6. CUSTOM
*/

@import 'vendor/bourbon/bourbon';
@import 'utils/mixin';
@import 'vendor/masonry_grid';
@import 'vendor/media_query';

@import 'utils/variable';

/*1. RESET AND TYPHOGRAPHY */
//1.1 reset
@import 'base/reset';
//1.2 typhography
@import 'base/typhography';

/*2. ELEMENTS */
//2.1 grid
@import 'elements/grid';
// 2.2 list
@import 'elements/list';
// 2.3 button
@import 'elements/button';
// 2.4 form
@import 'elements/form';
//2.5 post
@import 'elements/post';
// 2.6 pagination
@import 'elements/pagination';
// 2.7 breadcrumb
@import 'elements/breadcrumb';
// 2.8 block
@import 'elements/block';

@import 'elements/panel';
// 2.9 widget
@import 'elements/widget';
// 2.10 slider
@import 'elements/slider';
// 2.11 masonry
@import 'elements/masonry';
// 2.12 product
@import 'elements/product';
// 2.13 banner
@import 'elements/banner';
// 2.14 filter
@import 'elements/filter';
// 2.15 table
@import 'elements/table';
// 2.16 loader
@import 'elements/loader';
// 2.17 modal
@import 'elements/modal';
// 2.17 accordion

@import 'elements/accordion';

// 2.18 modal
@import 'elements/tabs';

/*3. COMPONENTS */
// 3.1 Cart
@import 'components/cart';
// 3.2 forms
@import 'components/forms';
// 3.3 search
@import 'components/search';
// 3.4 section
@import 'components/section';
// 3.5 pages
@import 'components/pages';

@import 'components/menu';

/*4. MODULES */
// 4.1 header
@import 'modules/header';
// 4.2 footer
@import 'modules/footer';

/*5. HELPERS */
@import 'elements/helpers';
