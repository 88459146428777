.ps-search {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 99999;
    @include transform-style(preserve-3d);
    @include hidden;
    @include transition(all .75s $timing-1);
    @include transition-delay(0);
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(#000, .95);
        @include transition(transform .6s $timing-1);
        @include transform(scale3d(1, 0, 1));
        @include transform-origin(50% 100%);
    }
    &__content {
        @include center();
        max-width: 1200px;
        width: 100%;
        padding: 0 3rem;
        @include center();
    }
    .ps-form--primary-search {
        @include transform(translateY(100%));
        @include transition(all .6s $timing-1);
        @include hidden;
    }
    > .ps-btn--close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        width: 50px;
        height: 50px;
        background-color: #000;
        z-index: 1000;
        @include transform(scale(0, 0) rotate(0));
        @include border-radius(50%);
        @include transition(all .6s $timing-1);
        border: none;
        &:before, &:after {
            background-color: #ffffff;
            height: 50%;
        }
        &:hover {
            background-color: #f44336;
        }
    }
    &.active {
        @include show;
        @include transition-delay(0s);
        &:before {
            @include transform(scale3d(1, 1, 1));
            @include transform-origin(50% 0%);
        }
        > .ps-btn--close {
            @include show;
            @include transform(scale(1, 1) rotate(180deg));
            @include transition-delay(.75s);
        }
        .ps-form--primary-search {
            @include transform(translateY(0));
            @include transition-delay(.35s);
            @include show;
        }

    }
    @include media("<xs") {
        > .ps-btn--close {

        }
        &__content {
            padding: 0 1.5rem;
        }
    }

}