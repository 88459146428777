.ps-loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: #fff;
    flex-flow: row nowrap;
    @include transition(all 1s $timing-1);
    &__content {
        height: 100vh;
        display: flex;
        flex-flow: row nowrap;
        > * {
            display: block;
            width: 25%;
            @include transition(transform 1s $timing-1);
            @include transform-origin(0 50%);
            background-color: #fcfaf6;
        }
    }
    &__1st {


    }
    &__2nd {

    }
    &__3rd {

    }
    &__4th {

    }
    &.loaded {
        @include hidden;
        background-color: transparent;
        @include transition-delay(.7s);
        .ps-loading__content {
            > * {
                @include transform(scale3d(0, 1, 1));

                @include transition-delay(.5s);
            }
        }
    }
}

#loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    overflow: hidden;
}

#loader-wrapper .loader-section {
    position: fixed;
    top: 0;
    width: 51%;
    height: 100%;
    background: #ffffff;
    z-index: 999;
}

#loader-wrapper .loader-section.section-left {
    left: 0;

}

#loader-wrapper .loader-section.section-right {
    right: 0;
}

#loader-wrapper img {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    margin-top: -50px;
    -webkit-animation: spin 1s linear infinite;
    -moz-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    z-index: 999999999;
}

.loaded #loader-wrapper img {
    opacity: 0;
    transition: all 0.3s ease-out;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/* Loaded styles */

.loaded #loader-wrapper .loader-section.section-left {
    transform: translateX(-100%);
    transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
}

.loaded #loader-wrapper .loader-section.section-right {
    transform: translateX(100%);
    transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
}

.loaded #loader-wrapper {
    visibility: hidden;
    transform: translateY(-100%);
    transition: all 0.3s 1s ease-out;
}
