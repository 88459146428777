.navigation {
    background-color: $color-1st;

    > .ps-container, > .container {
        display: flex;
        flex-flow: row nowrap;

        > * {
            width: 100%;
        }
    }

    &__extra {
        > li {
            position: relative;
            display: inline-block;
            margin-right: 20px;
            padding-right: 20px;

            &:after {
                content: '';
                @include vertical-align();
                right: 0;
                width: 2px;
                height: 15px;
                background-color: #000;
            }

            a {
                color: #000000;
            }

            &:last-child {
                margin-right: 0;
                padding-right: 0;

                &:after {
                    display: none;
                }
            }
        }

        .ps-dropdown {
            img {
                margin-right: 8px;
            }
        }
    }

    .navigation__left {
        max-width: 260px;
    }

    .navigation__right {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        padding-left: 35px;
    }
}

.header {
    @extend %list-reset;

    .header__extra {
        display: inline-block;
        position: relative;
        width: 30px;
        height: 42px;
        transition: all .4s ease;

        > i {
            font-size: 30px;
            line-height: 42px;
        }

        span {
            position: absolute;
            bottom: 0;
            right: -6px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            vertical-align: top;
            width: 20px;
            height: 20px;
            color: #ffffff;
            background-color: #000000;
            border-radius: 50%;

            i {
                font-size: 12px;
                font-style: normal;
                line-height: 1em;
                font-weight: 500;
            }
        }

        &:hover {
            i {
                color: #ffffff;
            }
        }
    }

    .header__actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        > * {
            margin: 0 20px;
        }
    }

    .header__left {
        max-width: 300px;
        display: flex;
        align-items: center;

        .menu--product-categories {
            display: none;
        }
    }

    .header__center {
    }

    .header__right {
        max-width: 370px;
    }

    .header__top {
        padding: 25px 0;
        background-color: $color-1st;
        border-bottom: 1px solid rgba(0, 0, 0, .15);

        .ps-container, .container {
            display: flex;
            flex-flow: row nowrap;

            > * {
                width: 100%;
            }

        }
    }

    &.header--sticky {
        .menu--product-categories {
            .menu__toggle {
                i {
                    font-size: 30px;
                }

                span {
                    font-size: 16px;
                }
            }
        }

        .header__top {
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 1000;
            border-bottom: none;
            padding: 10px 0;
        }

        .header__left {
            .ps-logo {
                display: none;
            }

            .menu--product-categories {
                display: block;
                background-color: transparent;
            }
        }
    }

    @include media("<lg") {
        display: none;
        &.header--mobile {
            display: block;
        }
    }
}

.header--standard {
    .ps-block--user-header {
        a {
            &:hover {
                color: $color-1st;
            }
        }
    }

    .header__top-links {
        width: 100%;

        > li {
            display: inline-block;
            margin-right: 16px;
            padding-right: 15px;
            position: relative;

            &:after {
                content: '';
                @include vertical-align();
                right: 0;
                height: 15px;
                width: 0;
                border-right: 1px solid #e1e1e1;
            }

            > a {
                color: #666666;
            }

            &:last-child {
                margin-right: 0;
                padding-right: 0;

                &:after {
                    display: none;
                }
            }
        }

        .language {
            > a {
                img {
                    margin-right: 5px;
                }
            }
        }

        .ps-block--user-header {
            .ps-block__left {
                i {
                    font-size: 14px;
                }
            }

            .ps-block__right {
                a {
                    display: inline-block;
                    font-weight: 400;
                    margin-right: 10px;
                    color: #666666;

                    &:first-child {
                        &:after {
                            content: '/';
                            padding-left: 10px;
                        }
                    }

                    &:hover {
                        color: $color-1st;
                    }
                }
            }
        }
    }

    .header__top {
        padding: 10px 0;
        background-color: #fff;
        border-bottom: none;

        .container {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
        }

        .header__left {
            max-width: 50%;

            p {
                margin-bottom: 0;
                color: #666666;
                line-height: 30px;

                strong {
                    color: $color-heading;
                }
            }
        }

        .header__right {
            text-align: right;
            max-width: 50%;
        }
    }

    .header__content-left {
        max-width: 270px;
        padding-right: 30px;
        display: flex;
        align-items: center;

        .ps-logo {
            margin-right: 30px;
        }
    }

    .header__content-center {
        max-width: 600px;
    }

    .menu--product-categories {
        .menu__toggle {
            i {
                font-size: 30px;
            }
        }
    }

    .ps-form--quick-search {
        width: 600px;

        button {
            background-color: #fcb800;
            color: $color-heading;
        }

        .form-group--icon {
            border: 1px solid #e1e1e1;
            min-width: 80px;
            border-radius: 4px 0 0 4px;

            select {
                height: 40px;
                border: none;
            }
        }

        input {
            border: 1px solid #e1e1e1;
            border-left: none;
        }
    }

    .header__content {
        padding: 20px 0;

        .menu--product-categories {
            .menu__toggle {
                &:hover {
                    &:before {
                        display: none;
                    }
                }
            }
        }

        .container {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            > * {
                width: 100%;
            }
        }
    }

    .header__extra {
        span {
            background-color: $color-1st;

            i {
                color: $color-heading;
                font-weight: 700;
            }
        }

    }

    &.header--sticky {

        .header__top {
            position: relative;
        }

        .header__content {
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 1000;
            border-bottom: 1px solid #ccc;
            padding: 10px 0;
            background-color: #ffffff;

            .menu--product-categories {
                display: block;
            }
        }

        .ps-logo {
            display: none;
        }
    }

    @include media("<lg") {
        display: none;
    }
}

.header--product {
    @include hidden;
    height: 0;

    .navigation {
        transition: transform .25s ease;
        transform: translateY(-50%);
    }

    &.header--sticky {
        @include show;
        height: auto;

        .navigation {
            padding: 10px 0;
            background-color: #fff;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 9999;
            border-bottom: 1px solid #eaeaea;
            transform: translateY(0);
        }
    }
}

.header--mobile {
    position: relative;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 9999;
    text-align: center;
    border-bottom: 0 none;
    background-color: $color-1st;
    @include clearfix();
    @include transition(all .4s $ease-in-out-quad);

    .navigation__extra {
        display: inline-block;

        li {
            margin-right: 5px;
            padding-right: 5px;

            &:after {
                display: none;
            }
        }
    }

    .header__extra {
        span {
            background-color: $color-1st;
        }
    }

    .header__actions {


        i {
            font-size: 24px;
        }
    }

    .ps-block--user-header {
        i {
            font-size: 24px;
        }
    }

    .header__top {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        background-color: #f4f5f5;
        padding: 15px 30px;

        > * {
            width: 100%;
        }

        .header__left {
            max-width: 40%;

            p {
                margin-bottom: 0;
            }
        }

        .header__right {
            max-width: 60%;
            text-align: right;

            .navigation__extra {
                display: inline-block;
            }
        }
    }

    .navigation--mobile {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding: 10px 30px;
        border-bottom: none;

        .navigation__left {
            display: flex;
            align-items: center;
        }
    }

    .header__extra {
        span {
            background-color: #000;

            i {
                color: #ffffff;
                font-weight: 600;
            }
        }
    }

    &.active {
        @include transform(translateX(270px));
    }

    &.header--sticky {
        .navigation--mobile {
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 1000;
            background-color: $color-1st;
            @include box-shadow(0 0 3px 0 rgba(#000, .12));
        }
    }

    @media (min-width: 1200px) {
        display: none;
    }

    @include media("<lg") {
        .ps-search--mobile {
            display: none;
        }
    }

    @include media("<md") {
        .header__top {
            .header__left {
                display: none;
            }

            .header__right {
                max-width: 100%;
                text-align: center;
            }
        }
    }

    @include media("<sm") {
        .navigation__extra {
            li {
                > a {
                    display: none;
                }
            }
        }
    }

    @include media("<xs") {
        .header__top {
            display: none;
        }
        .header__actions {
            > * {
                margin: 0;
                margin-right: 20px;
            }

            i {
                font-size: 24px;
            }
        }
        .ps-block--user-header {
            margin-right: 0;

            i {
                font-size: 24px;
            }

            .ps-block__right {
                display: none;
            }
        }
        .ps-search--mobile {
            display: block;
        }
    }

    .navigation--mobile {
        .ps-logo {

        }
    }
}

.header--mobile-product {
    .header__back {
        vertical-align: middle;
        text-align: left;

        strong {
            font-size: 18px;
            vertical-align: middle;
        }

        i {
            vertical-align: middle;
            margin-right: 16px;
            font-size: 24px;
            color: $color-heading;
        }
    }

    .navigation--mobile {
        .navigation__left {
            justify-content: flex-start;
        }
    }
}

.header--mobile-categories {
    .header__back {
        vertical-align: middle;
        text-align: left;

        strong {
            font-size: 18px;
            vertical-align: middle;
        }

        i {
            vertical-align: middle;
            margin-right: 16px;
            font-size: 24px;
            color: $color-heading;
        }
    }

    .navigation--mobile {
        .navigation__left {
            justify-content: flex-start;
        }
    }

    .header__filter {
        padding: 10px 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #f5f5f5;

        > * {
            flex-basis: 100%;
            max-width: 50%;
        }

        button {
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30px;
            background-color: transparent;
            font-size: 14px;

            &:after {
                content: '\e93a';
                font-family: Linearicons;
                margin-left: 20px;
            }

            i {
                margin-right: 10px;
                font-size: 16px;
                font-size: 20px;
                color: $color-text;
            }

        }

        .header__sort {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 10px;
            i {
                margin-right: 10px;
                font-size: 16px;
                font-size: 20px;
                color: $color-text;
            }

            .select2 {
                .select2-selection {
                    border: none;
                    background-color: transparent;
                    .select2-selection__rendered {
                        background-color: transparent;
                        text-align: left;
                    }
                    .select2-selection__arrow {
                        &:before {
                            content: '\e93a';
                            font-family: Linearicons;
                        }
                    }
                }
            }
        }

        > button {
            border-right: 1px solid #ccc;
        }
    }
}

.navigation--sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 320px;
    height: 100vh;
    overflow-y: auto;
    z-index: 10001;
    background-color: #fff;
    @include transform(translateX(-100%));
    transition: all .5s cubic-bezier(0.7, 0, .3, 1) 0s;
    @extend %list-reset;

    .navigation__actions {
        text-align: center;

        .ps-dropdown {
            margin-bottom: 10px;

            > a {
                font-size: 1.4rem;
                color: $color-text;
            }
        }
    }

    .navigation__header {
        position: relative;
        text-align: center;
        padding: 15px 0;
        background-color: $color-1st;

        h3 {
            margin-bottom: 0;
            font-weight: 600;
            color: #ffffff;
            font-size: 1.6rem;
            line-height: 20px;
            text-transform: uppercase;
            letter-spacing: .05em;
        }

        .ps-btn--close {
            @include vertical-align();
            right: 10px;

            &:before, &:after {
                background-color: #ffffff;
                height: 60%;
            }
        }
    }

    .navigation__content {
        padding-top: 10px;
        padding-bottom: 70px;
    }

    .header__actions {
        a {
            display: inline-block;
            margin: 0 5px;
            line-height: 40px;

            i {
                font-size: 24px;
                color: $color-heading;
                vertical-align: middle;
            }
        }

        .ps-dropdown {
            a {
                padding-right: 0;

                &:after {
                    display: none;
                }
            }
        }

        .ps-cart-toggle {
            position: relative;
            display: inline-block;
            padding: 5px 0;
            line-height: 30px;

            span {
                position: absolute;
                top: 40%;
                left: 50%;
                width: 20px;
                height: 20px;
                @include border-radius(50%);
                @include transform(translate(25%, -100%));
                background-color: #000;

                i {
                    @include center();
                    color: #ffffff;
                    font-style: normal;
                    font-size: 11px;
                }
            }
        }
    }

    &.active {
        @include transform(translateX(0));
    }

    @media (min-width: 1200px) {
        .navigation__actions {
            display: none;
        }
    }

    @include media("<xs") {
        width: 100%;
    }
}

.navigation--list {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10002;
    padding: 10px 30px;
    border-top: 1px solid #ccc;
    background-color: #ffffff;

    .navigation__item {
        text-align: center;

        i {
            font-size: 24px;
        }

        span {
            display: block;
        }

        &.active {
            i {
                color: $color-1st;
            }

            span {
                color: $color-1st;
            }
        }
    }

    .navigation__content {
        max-width: 400px;
        margin: 0 auto;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
    }

    @media (min-width: 1200px) {
        display: none;
    }
}

.navigation--mobile-product {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10002;
    border-top: 1px solid #ccc;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;

    > * {
        width: 100%;
        flex-basis: 50%;
        font-size: 16px;
        border-radius: 0;
        text-align: center;
    }

    .ps-btn--black {
        color: #ffffff;
    }

    @media (min-width: 1200px) {
        display: none;
    }
}

.navigation--sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: #fff;

    .navigation {
        background-color: #fff;
    }

    .header__top {
        display: none;
    }

    &.navigation--pin {
        @include transform(translate(0, 0));
    }

    &.navigation--unpin {
        @include transform(translate(0, -100%));
    }

    &.header--1 {
        &.navigation--pin {
            .navigation {
                background-color: rgba(#000, .95);
            }

            &.header--black {
                .navigation {
                    background-color: rgba(#fff, .95);
                }
            }
        }
    }

    &.header--2 {
        &.navigation--pin {
            &.active {
                background-color: #fff;
                @include transform(translate(270px, 0));
            }
        }
    }

    @include media("<lg") {
        &.navigation--unpin {
            @include transform(translate(0, 0));
        }
    }
}

.ps-site-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    height: 100vh;
    background-color: rgba(#000, .75);
    @include transition(all .4s ease);
    @include hidden;

    &.active {
        @include show;
    }
}

.ps-search--mobile {
    max-width: 450px;
    margin: 0 auto;
    padding: 10px 20px;
}