$font-1st: 'Work Sans', sans-serif;
$font-2nd: 'Libre Baskerville', serif;
$font-3rd: 'Playfair Display', serif;
$home: true !default;

$color-1st: #fcb800;
$color-layout-2-1st: #dd2400;
$color-autopart: #dd2400;
$color-technology: #0071df;
$color-electronic: #dd2400;;
$color-furniture: #fb7c00;;
$color-organic: #5fa30f;
$color-kids: #3e81ac;
$color-photo: #c81919;
$color-med: #28afb1;

$color-2nd: #222222;
$color-3rd: #5e6b9d;
$color-heading: #000;
$color-text: #666;
$color-text-2: #555;
$color-dark: #161824;
$color-link: #06c;
$color-success: #669900;
$color-warning: #FAA806;
$color-danger: #ec0101;
$color-gray: #9e9e9e;
$color-facebook: #3b5999;
$color-twitter: #55acee;
$color-google: #dd4b39;
$color-linkedin: #0077B5;
$color-instagram: #0077B5;
$document-font-size: 14px;

$timing: cubic-bezier(0.77, 0, 0.175, 1);
$timing-1: cubic-bezier(0.7, 0, 0.3, 1);
$timing-2: cubic-bezier(0.2, 1, 0.3, 1);
